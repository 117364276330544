.custom-toggle .react-toggle-track-x,
.react-toggle-track-check {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
}

.custom-toggle .react-toggle-thumb {
  background-color: #3788b3 !important;
  border: 1px solid #f2f2f2 !important;
}

.custom-toggle .react-toggle-track {
  background-color: #f2f2f2 !important;
  border: 1px solid #3788b3 !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #fff !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none !important;
}
